<template>
  <div class="admin-box">
    <div class="admin-body">
      <router-view v-slot="{ Component }">
        <transition name="no-mode-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div class="tabbar-box">
      <van-tabbar v-model="active">
        <van-tabbar-item to="/admin/staff-list" replace>员工列表</van-tabbar-item>
        <!-- <van-tabbar-item to="/admin/role">角色列表</van-tabbar-item> -->
        <!-- <van-tabbar-item to="/admin/auth-management">
          <span>权限管理</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item> -->
        <van-tabbar-item to="/admin/system-settings" replace
          >系统配置</van-tabbar-item
        >
      </van-tabbar>
    </div>
    <div class="go-home">
      <van-button round plain hairline type="primary" size="large" @click="goHome()">
        <van-icon name="wap-home-o" />
      </van-button>
    </div>
  </div>
</template>

<script>
import { ref, watch, onUnmounted } from "vue";
import { Button, Icon, Tabbar, TabbarItem } from "vant";
import Sjurisdiction from "../../assets/image/s-jurisdiction.png";
import jurisdiction from "../../assets/image/jurisdiction.png";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "Admin",
  components: {
    "van-tabbar": Tabbar,
    "van-tabbar-item": TabbarItem,
    "van-button": Button,
    "van-icon": Icon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter()
    const active = ref();
    const at = localStorage.getItem("active");
    if (!at) {
      active.value = 0;
    } else {
      active.value = Number(at);
    }
    const icon = {
      active: Sjurisdiction,
      inactive: jurisdiction,
    };

    // 回首页
    const goHome = () => {
      router.push({path:'/',replace:true})
    }
    // 监听路由变化，改变导航栏的选中状态
    watch(
      () => route.path,
      () => {
        if (route.path == "/admin/staff-list") {
          active.value = 0;
        } else if (route.path == "/admin/role") {
          active.value = 1;
        } else if (route.path == "/admin/auth-management") {
          active.value = 2;
        }
        localStorage.setItem("active", active.value);
      }
    );
    // 组件销毁
    onUnmounted(() => {
      localStorage.setItem("active", 0);
    });
    return {
      active,
      icon,
      goHome,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.table-box {
  //   margin: 0 20px;
  box-sizing: border-box;
}
.fl-table {
  border-radius: 10px;
  font-size: 24px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  thead {
    th {
      color: #ffffff;
      background: #4fc3a1;
    }
    th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
    }
  }
  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
  }
  tr:nth-child(even) {
    background: #f8f8f8;
  }
}
tr {
  height: 80px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.admin-body-header {
  margin: 40px;
  font-weight: 800;
  font-size: 40px;
}
.user-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  margin-left: 60px;
}
.gt-span {
  margin-left: 10px;
  display: inline-block;
  transform: rotate(90deg);
}
.submit-btn {
  margin-top: 90px;
  margin-right: 20px;
  margin-left: 20px;
}
.span-status {
  margin-right: 60px;
}
.go-home {
  position: fixed;
  width: 104px;
  bottom: 100px;
  left: 20px;
  border-radius: 50%;
    background-color: white;
    z-index: 999;
    box-shadow: 1px 1px 10px lightblue;
}
::v-deep .pagination .van-pagination__item {
  height: 64px !important;
}
.no-mode-fade-enter-active,
.no-mode-fade-leave-active {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
}

.no-mode-fade-enter-from,
.no-mode-fade-leave-to {
  opacity: 0;
}
::v-deep .van-tabbar-item,
::v-deep .van-tab,
::v-deep .van-cell,
::v-deep .van-button {
  font-size: 40px;
}
</style>
